.portfolio-page {
    padding-left: 100px;
    padding-right: 50px;
    width: calc(100% - 150px);
    position: initial;
    height: 100%;
    overflow: auto;

    .split-para      { display:block;margin:10px;}
    .split-para span { display:block;float:right;width:50%;margin-left:10px;}
    
    .work-experience,
    .education {
        text-align: center;
        
    }

    .flat-button {
        color: #474350;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #474350;
        margin-top: 25px;
        animation: fadeInAnimation 1s 1.8s backwards;
        white-space: nowrap;
        background-color: #ffffff;
    
        &:hover {
          background: #009bf5;
          border: 1px solid #009bf5;
          color: #333;
        }
      }

    .page-title {
        color: #ffffff;
        .text-animate-hover {
            &:hover {
                color: #009bf5;
            }
    }
     }

    .work-experience {
        color: #ffffff;
    }
    span.text-animate-hover{
        &:hover {
            color: #00c398;
        }
    }


    .education {
        color: #ffffff;
        width: 100%;
        margin-left: auto;
    }
   

    .moreProjects {
        font-size: 20px;
        text-align: center;
        border: 2px solid #ffffff;
        padding: 10px 18px;
        background-color: #ffffff;
        color: #000;
        border-radius: 5px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        a {
            color: #000;
            font-style: 13px;
            font-family: sans-serif;
            font-weight: 300;
            padding-left: 10px;
            padding-right: 10px;
    
            &:hover {
                color: #009bf5;
              }
        }
    }

    .content {
        padding: 0px 50px 50px 50px;
        display: flex;
        gap: 35px;
        flex-wrap: wrap;
        justify-content: center;
    }

    .resumeCard {
        width: 28%;
        background-color: white;
        color: #1e2d2fe8;
        border-radius: 5px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        text-align: center;
        padding: 15px;
        min-width: 280px;

        h2 {
            
            font-size: 20px;
        }
        h3 {
            font-size: 12px;
        }

        p {
            font-size: 12px;
        }

        img:hover {
            transform: scale(1.05);
        }

        .flat-button {
            color: #474350;
            font-size: 13px;
            font-weight: 400;
            letter-spacing: 4px;
            font-family: sans-serif;
            text-decoration: none;
            padding: 10px 18px;
            border: 1px solid #474350;
            margin-top: 25px;
            animation: fadeInAnimation 1s 1.8s backwards;
            white-space: nowrap;

            &:hover {
                background: #009bf5;
                border: 1px solid #009bf5;
                color: #333;
              }
        }
    }
    
    .card h2 {
        font-size: 1.5em;
    }

    h1.page-title {
        margin-left: 100px;
        margin-top: 100px;
    }

    .images-container {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        padding-bottom: 100px;
    }

    .image-box {
        position: relative;
        flex: 1 1 20%;
        height: 400px;
        overflow: hidden;
        border-radius: 10px;
        max-width: calc(25% - 10px);

        .portfolio-image {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .content {
            position: absolute;
            width: 100%;
            z-index: 3;
            padding: 10px 20px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.2) 0,
                rgba(0, 0, 0, 1)
            );
            bottom: -70px;
        }

        .title {
            margin-bottom: -80px;
            margin-top: 0;
            color: #fff;
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;

            
        }

        .description {
            font-size: 14px;
            margin-bottom: 5px;
            color: #fff;
            font-weight: 700;
        }

        .btn {
            margin-top: 30px;
            margin-bottom: 10px;
            padding: 0 23px;
            height: 40px;
            line-height: 34px;
            border: 2px solid #ffd700;
            border-radius: 4px;
            font-size: 14px;
            color: #fff;
            background: transparent;
            text-transform: uppercase;
            font-weight: 700;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            cursor: pointer;
        }

        .btn:hover {
            transform: translateY(-3px);
            background: #ffd700;
        }

        &:after {
            content: "";
            background: linear-gradient(180deg, #ffd700, #000);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            opacity: 0;
        }

        &:hover:after {
            opacity: 0.85;
        }

        &:hover .content {
            bottom: 0;
            background: transparent;
        }

        .flat-button {
            float: left;
            margin: 20px auto 0 auto;
          }
    }
}