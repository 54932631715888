.logo-container {
  z-index: 0;
  width: 600px;
  height: 609px;
  opacity: 1;
  position: absolute;
  top: 0;
  right: 15%;
  bottom: 0;
  left: auto;
  margin: auto;

  .solid-logo {
    position: absolute;
    top: auto;
    padding-top: 100px;
    padding-left: 100px;
    right: auto;
    bottom: auto;
    left: 0;
    margin: auto;
    width: 100%;
    opacity: 1;
    transform: rotateZ(20deg) !important;
    z-index: 1;
  }
}