.contact-form {
    width: 100%;
    margin-top: 20px;
  
    ul {
      padding: 0;
      margin: 0;
  
      li {
        padding: 0;
        margin: 0;
        list-style: none;
        margin-bottom: 10px;
        opacity: 0;
        overflow: hidden;
        display: block;
        clear: both;
        position: relative;
        animation: fadeInUp 2s 2s;
        animation-fill-mode: forwards;
      }
  
      li.half {
        width: 49%;
        margin-left: 2%;
        float: left;
        clear: none;
  
        &:first-child {
          margin-left: 0;
        }
      }
    }
  
    input[type='text'],
    input[type='email'] {
      width: 100%;
      border: 0;
      background: #115173;
      height: 50px;
      font-size: 16px;
      color: #fff;
      padding: 0 20px;
      box-sizing: border-box;
    }
  
    textarea {
      width: 100%;
      border: 0;
      background: #115173;
      height: 50px;
      font-size: 16px;
      color: #fff;
      padding: 20px;
      box-sizing: border-box;
      min-height: 150px;
    }
  
    .flat-button {
      color: #ffd700;
      font-size: 11px;
      letter-spacing: 3px;
      text-decoration: none;
      padding: 8px 10px;
      border: 1px solid #ffd700;
      float: left;
      border-radius: 4px;
      background: 0 0;
      text-transform: uppercase;
      float: right;
      text-align: center;
      margin-right: 10px;
    }
  }

  .contact-content {
    padding: 0px 50px 50px 50px;
    display: flex;
    flex-wrap: wrap;
    right: 0;
    top: 0;
    
}

  .contact-card {
    width: 40%;
    background-color: white;
    color: #1e2d2fe8;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 15px;
    margin-left: calc(60% - 100px);
    margin-top: calc(18% - 100px);

    a {
      color: black;

      &:hover {
        color: #009bf5;
      }
    }

    
    hr {
      size: 5;
      color: black;
    }

    h1 {
      font-size: 30px;
      text-align: center;
    }

    h2 {
      font-size: 20px;
      text-align: center;
    }

    h3 {
      font-size: 15px;
    }
  }
  
  .leaflet-container {
    position: relative;
    width: 100%;
    height: 100%;
    opacity: 0;
    animation: backInRight 1s 1.2s;
    animation-fill-mode: forwards;
  }
  
  .info-map {
    position: absolute;
    background: #000;
    top: 50px;
    right: 30%;
    z-index: 999999;
    width: 267px;
    padding: 20px;
    color: #fff;
    font-family: 'Helvetica';
    font-size: 17px;
    font-weight: 300;
    opacity: 0;
    animation: fadeIn 1s 1.5s;
    animation-fill-mode: forwards;
  
    span {
      font-size: 16px;
  
      span {
        color: #ffd700;
      }
    }
  }
  
  @media screen and (max-width: 1200px) {
    .map-wrap {
      float: none;
      margin: 0;
      width: 100%;
      height: 400px;
    }

    .contact-card {
      width: 28%;
      margin-left: calc(0% - 0px);
      margin-top: calc(0% - 0px);

    }

    input[type='text'],
    input[type='email'], 
    input[type='textarea'],
    input[type='submit'] {
      align-content: center;
      width: 90%
    }

    .content {
      justify-content: center;
    }
    
    .contact-card{
      width: 80%;
    }

  }